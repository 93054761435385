import { Input, Pagination, Tag } from "antd";
import React, { useEffect, useState } from "react";
import emptyBox from "../../assets/img/empty-box.png";
import { ROWS } from "../../util/constant";
import { convertTime, getPaymentMethod } from "../../util/function";
import { axiosService } from "../../util/service";

export default function Order() {
  // data
  const [listData, setListData] = useState([]);

  // pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  // keyword
  const [keyword, setKeyword] = useState("");

  const onPaginationChange = (page) => {
    setCurrent(page);
    getHistory(ROWS, page);
  };

  const getHistory = async (limit, page) => {
    try {
      let response = await axiosService.post("api/admin/getOrderHistoryAdmin", {
        limit,
        page,
      });
      setListData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCurrent(1);
    getHistory(ROWS, 1);
  }, []);

  const searchOrder = async (keyword) => {
    try {
      let response = await axiosService.post("api/admin/searchOrder", { keyword });
      // khi search chỉ trả về 10 kết quả đầu tiên
      setListData(response.data.data.array);
      setTotalRecord(10);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeKeyword = (e) => {
    const k = e.target.value;
    setKeyword(k);

    if (!k) {
      setKeyword("");
      getHistory(ROWS, 1);
      setCurrent(1);
    }
  };

  const onSearch = () => {
    // hàm này gọi khi: enter, nhấn nút search, hoặc clear bằng nút x (allowClear)
    // nếu có keyword thì search, không có thì getAllProducts
    if (keyword && keyword.length > 0) {
      searchOrder(keyword);
    } else {
      getHistory(ROWS, 1);
      setCurrent(1);
    }
  };

  const renderProduct = (product, i, orderID) => {
    return (
      <div className="item-p" key={i}>
        <div className="item-p-child-1">
          <img src={product.img} alt={product.name} />

          <div className="info">
            <p className="info-name">{product.name}</p>

            <span>{product.variant}</span>

            <span>
              Color: <i className="fa-solid fa-circle" style={{ color: product.color }} />
            </span>
          </div>
        </div>

        <div className="item-p-child-2">
          <div className="price">
            <span>
              Giá: <strong>{product.price.toLocaleString()}</strong>
            </span>
            <span>
              Số lượng: <strong>{product.amount}</strong>
            </span>
            <span>
              Tổng: <strong>{product.total_price.toLocaleString()}</strong>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const render = (order, index) => {
    return (
      <div className="order-history-item" key={index}>
        <div className="first-row">
          <Tag color="green">
            <b>Giao hàng thành công</b>
          </Tag>
        </div>

        <div className="third-row order-info">
          <span>
            Mã đơn hàng: #<strong>{order.id}</strong>
          </span>
          <span>
            Thời gian đặt hàng: <strong>{convertTime(order.order_time)}</strong>
          </span>
          <span>
            Thời gian giao hàng: <strong>{convertTime(order.delivery_time)}</strong>
          </span>
          <span>
            Người nhận:{" "}
            <strong>
              {order.ship_name} | {order.ship_email} | {order.ship_phone}
            </strong>
          </span>
          <span>
            Địa chỉ giao hàng:{" "}
            <strong>
              {order.ship_address} | {order.ship_village} | {order.ship_district} | {order.ship_province}
            </strong>
          </span>
          <span>
            Phí vận chuyển: <strong>{order.delivery_fee.toLocaleString()}</strong>
          </span>
          <span>
            Tổng tiền: <strong>{order.total_price_final.toLocaleString()}</strong>
          </span>
          <span>
            Hình thức thanh toán: <strong>{getPaymentMethod(order.ship_payment)}</strong>
          </span>
        </div>

        <div className="second-row product-list">
          {order.productsList.map((product, i) => {
            return renderProduct(product, i, order.id);
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="order-component">
      <div className="title-area">
        <h2 className="title">Quản lý đơn hàng</h2>

        <Input.Search
          placeholder="Tìm kiếm đơn hàng"
          enterButton
          className="search-box"
          allowClear
          onSearch={onSearch}
          onChange={onChangeKeyword}
        />

        <Pagination
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          className="pagination-box"
          pageSize={ROWS}
        />
      </div>

      {listData.length == 0 && (
        <div className="no-data">
          <img src={emptyBox} alt="Không có dữ liệu" />
          <div>Không tìm thấy đơn hàng nào</div>
        </div>
      )}

      {listData.length != 0 &&
        listData.map((order, index) => {
          return render(order, index);
        })}
    </div>
  );
}
