import { Button, Input, Select } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { convertString } from "../../util/function";
import { axiosService, DOMAIN } from "../../util/service";
import { showAlert } from "../../util/showAlert";

const { TextArea } = Input;

export default function AddProduct() {
  const [listCategory, setListCategory] = useState([]);
  const [listSubCategory, setListSubCategory] = useState([]);

  const [selected2, setSelected2] = useState(null);
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");

  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: null,
      subCategory: null,
      sku: "",
      name: "",
      price: "",
      isDiscount: false,
      discountPrice: null,
      isBestSeller: false,
      isNew: false,
      stock: "",
      color: "",
      variant: "",
      promotion: "",
      detail: "",
      material: "",
      guide: "",
      img1: "",
      img2: "",
      img3: "",
      img4: "",
    },
    onSubmit: (values) => {
      if (!values.category || !values.subCategory) {
        showAlert(2, "Chưa chọn danh mục");
        return;
      }

      let priceNum = Number(values.price);
      if (priceNum <= 0 || isNaN(priceNum) || !Number.isInteger(priceNum)) {
        showAlert(2, "Kiểm tra lại giá bán");
        return;
      }

      if (values.discountPrice != null && Number(values.discountPrice) >= Number(values.price)) {
        showAlert(2, "Giá giảm phải nhỏ hơn giá gốc");
        return;
      }

      let stockNum = Number(values.stock);
      if (stockNum <= 0 || isNaN(stockNum) || !Number.isInteger(stockNum)) {
        showAlert(2, "Kiểm tra lại số lượng tồn kho");
        return;
      }

      if (!values.img1 || !values.img2 || !values.img3 || !values.img4) {
        showAlert(2, "Chưa chọn hình ảnh");
        return;
      }

      addProduct({
        sku: values.sku,
        name: values.name,
        price: Number(values.price),
        is_best_seller: values.isBestSeller ? "1" : "0", // 1 hoặc 0 dạng chuỗi để tránh bị empty khi validation
        is_new: values.isNew ? "1" : "0",
        is_discount: values.isDiscount ? "1" : "0",
        discount_price: values.discountPrice == null ? "null" : Number(values.discountPrice), // string null để tránh bị empty khi validation
        category_id: Number(values.category),
        category_name: name1,
        sub_category_id: Number(values.subCategory),
        sub_category_name: name2,
        stock: Number(values.stock),
        variant: convertString(values.variant),
        color: convertString(values.color),
        promotion: convertString(values.promotion),
        detail: values.detail,
        material: values.material,
        guide: values.guide,
        img1: values.img1,
        img2: values.img2,
        img3: values.img3,
        img4: values.img4,
      });
    },
  });

  const handleCheckboxChange = (e) => {
    formik.setFieldValue("isDiscount", e.target.checked);
    if (e.target.checked == false) {
      formik.setFieldValue("discountPrice", null);
    }
  };

  const getData = async () => {
    try {
      let response = await axiosService.get("api/ecommerce/getAllCategories");
      setListCategory(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (formik.values.category) {
      // khi danh mục chọn xong
      const temp = listCategory.find((cat) => cat.id == formik.values.category);
      setListSubCategory(temp.children);

      // sau khi có danh sách danh mục con thì set thằng đầu tiên mặc định luôn
      setSelected2(temp.children[0]?.id);
      formik.setFieldValue("subCategory", temp.children[0]?.id);
      setName2(temp.children[0]?.name);
    }
  }, [formik.values.category]);

  const handleChange = (value) => {
    formik.setFieldValue("category", value);

    const item = listCategory.find((item) => item.id == value);
    setName1(item.name);
  };

  const handleChange2 = (value) => {
    setSelected2(value);
    formik.setFieldValue("subCategory", value);

    const item = listSubCategory.find((item) => item.id == value);
    setName2(item.name);
  };

  const addProduct = async (data) => {
    try {
      let response = await axiosService.post("api/admin/addProduct", data);
      showAlert(1, response.data.message);
      history.push("/product");
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const uploadImage = async (img, formikField) => {
    try {
      let fd = new FormData();
      fd.append("image", img);

      let response = await axiosService.post("api/admin/uploadImage", fd);
      formik.setFieldValue(formikField, DOMAIN + "images/" + response.data.data.filename);
      showAlert(1, response.data.message);
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.data.message);
    }
  };

  const handleUploadImage = (inputID, formikField) => {
    const files = document.getElementById(inputID).files;
    if (!files || files.length == 0) {
      showAlert(2, "Chưa chọn hình ảnh");
      return;
    }
    const file = files[0];
    uploadImage(file, formikField);
  };

  const handleClearImage = (inputID, formikField) => {
    formik.setFieldValue(formikField, "");
    const inputElement = document.getElementById(inputID);
    inputElement.value = "";
  };

  const handleSeeImage = (formikField) => {
    if (!formik.values[formikField]) {
      showAlert(2, "Không tìm thấy hình ảnh");
      return;
    }
    window.open(formik.values[formikField]);
  };

  return (
    <div className="add-product">
      <div className="title-area">
        <h2 className="title">Thêm sản phẩm</h2>

        <div className="pagination-box">
          <Button type="primary" onClick={formik.handleSubmit}>
            Lưu sản phẩm
          </Button>
        </div>
      </div>

      <div className="form-content">
        <form>
          <div className="field category-field">
            <label htmlFor="category">Danh mục</label>

            <Select
              style={{
                width: "100%",
              }}
              onChange={handleChange}
              options={listCategory.map((cat) => {
                return {
                  value: cat.id,
                  label: cat.name,
                };
              })}
            />
          </div>

          {/* danh mục chọn xong mới đến danh mục con */}
          {formik.values.category && (
            <div className="field subcategory-field">
              <label htmlFor="subcategory">Danh mục con</label>

              <Select
                value={selected2}
                style={{
                  width: "100%",
                }}
                onChange={handleChange2}
                options={listSubCategory.map((subCat) => {
                  return {
                    value: subCat.id,
                    label: subCat.name,
                  };
                })}
              />
            </div>
          )}

          <div className="field sku-field">
            <label htmlFor="sku">SKU</label>
            <Input id="sku" className="sku" name="sku" value={formik.values.sku} onChange={formik.handleChange} />
          </div>

          <div className="field name-field">
            <label htmlFor="name">Tên sản phẩm</label>
            <Input id="name" className="name" name="name" value={formik.values.name} onChange={formik.handleChange} />
          </div>

          <div className="field price-field">
            <label htmlFor="price">Giá</label>
            <Input
              id="price"
              className="price"
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field isDiscount-field">
            <label htmlFor="isDiscount">Có giảm giá?</label>
            <input
              type={"checkbox"}
              id="isDiscount"
              className="isDiscount"
              name="isDiscount"
              checked={formik.values.isDiscount}
              onChange={handleCheckboxChange}
            />
          </div>

          {formik.values.isDiscount ? (
            <div className="field discountPrice-field">
              <label htmlFor="discountPrice">Giá sau giảm</label>
              <Input
                id="discountPrice"
                className="discountPrice"
                name="discountPrice"
                value={formik.values.discountPrice}
                onChange={formik.handleChange}
              />
            </div>
          ) : null}

          <div className="field isBestSeller-field">
            <label htmlFor="isBestSeller">Best seller?</label>
            <input
              type={"checkbox"}
              id="isBestSeller"
              className="isBestSeller"
              name="isBestSeller"
              checked={formik.values.isBestSeller}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field isNew-field">
            <label htmlFor="isNew">Mới?</label>
            <input
              type={"checkbox"}
              id="isNew"
              className="isNew"
              name="isNew"
              checked={formik.values.isNew}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field stock-field">
            <label htmlFor="stock">Tồn kho</label>
            <Input
              id="stock"
              className="stock"
              name="stock"
              value={formik.values.stock}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field color-field">
            <label htmlFor="color">Màu sắc</label>
            <Input
              placeholder="Nhập tên màu cách nhau bởi dấu chấm phẩy"
              id="color"
              className="color"
              name="color"
              value={formik.values.color}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field variant-field">
            <label htmlFor="variant">Lựa chọn</label>
            <Input
              placeholder="Nhập các lựa chọn cách nhau bởi dấu chấm phẩy"
              id="variant"
              className="variant"
              name="variant"
              value={formik.values.variant}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field promotion-field">
            <label htmlFor="promotion">Khuyến mãi</label>
            <Input
              placeholder="Nhập các khuyến mãi cách nhau bởi dấu chấm phẩy"
              id="promotion"
              className="promotion"
              name="promotion"
              value={formik.values.promotion}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field detail-field">
            <label htmlFor="detail">Chi tiết</label>
            <TextArea
              rows={3}
              id="detail"
              className="detail"
              name="detail"
              value={formik.values.detail}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field material-field">
            <label htmlFor="material">Chất liệu</label>
            <TextArea
              rows={3}
              id="material"
              className="material"
              name="material"
              value={formik.values.material}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field guide-field">
            <label htmlFor="guide">Hướng dẫn</label>
            <TextArea
              rows={3}
              id="guide"
              className="guide"
              name="guide"
              value={formik.values.guide}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field img1-field">
            <label htmlFor="img1">Hình ảnh 1</label>

            <div className="upload-img-field">
              <input type="file" className="input-file" id="input-file-img1" />

              <div className="btn-groups">
                <Button size="small" onClick={() => handleUploadImage("input-file-img1", "img1")}>
                  Upload
                </Button>
                <Button
                  size="small"
                  onClick={() => handleClearImage("input-file-img1", "img1")}
                  style={{ marginLeft: 15 }}
                >
                  Xoá
                </Button>
                <Button size="small" onClick={() => handleSeeImage("img1")} style={{ marginLeft: 15 }}>
                  Xem
                </Button>
              </div>

              <Input
                disabled
                id="img1"
                className="img1"
                name="img1"
                value={formik.values.img1}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="field img2-field">
            <label htmlFor="img2">Hình ảnh 2</label>

            <div className="upload-img-field">
              <input type="file" className="input-file" id="input-file-img2" />

              <div className="btn-groups">
                <Button size="small" onClick={() => handleUploadImage("input-file-img2", "img2")}>
                  Upload
                </Button>
                <Button
                  size="small"
                  onClick={() => handleClearImage("input-file-img2", "img2")}
                  style={{ marginLeft: 15 }}
                >
                  Xoá
                </Button>
                <Button size="small" onClick={() => handleSeeImage("img2")} style={{ marginLeft: 15 }}>
                  Xem
                </Button>
              </div>

              <Input
                disabled
                id="img2"
                className="img2"
                name="img2"
                value={formik.values.img2}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="field img3-field">
            <label htmlFor="img3">Hình ảnh 3</label>

            <div className="upload-img-field">
              <input type="file" className="input-file" id="input-file-img3" />

              <div className="btn-groups">
                <Button size="small" onClick={() => handleUploadImage("input-file-img3", "img3")}>
                  Upload
                </Button>
                <Button
                  size="small"
                  onClick={() => handleClearImage("input-file-img3", "img3")}
                  style={{ marginLeft: 15 }}
                >
                  Xoá
                </Button>
                <Button size="small" onClick={() => handleSeeImage("img3")} style={{ marginLeft: 15 }}>
                  Xem
                </Button>
              </div>

              <Input
                disabled
                id="img3"
                className="img3"
                name="img3"
                value={formik.values.img3}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="field img4-field">
            <label htmlFor="img4">Hình ảnh 4</label>

            <div className="upload-img-field">
              <input type="file" className="input-file" id="input-file-img4" />

              <div className="btn-groups">
                <Button size="small" onClick={() => handleUploadImage("input-file-img4", "img4")}>
                  Upload
                </Button>
                <Button
                  size="small"
                  onClick={() => handleClearImage("input-file-img4", "img4")}
                  style={{ marginLeft: 15 }}
                >
                  Xoá
                </Button>
                <Button size="small" onClick={() => handleSeeImage("img4")} style={{ marginLeft: 15 }}>
                  Xem
                </Button>
              </div>

              <Input
                disabled
                id="img4"
                className="img4"
                name="img4"
                value={formik.values.img4}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
