import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import avatar from "../../assets/img/boy.png";
import logo from "../../assets/img/logo.png";
import Tippy from "@tippyjs/react/headless";

export default function Header() {
  const dispatch = useDispatch();
  const history = useHistory();

  const toggleSidebar = () => {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
  };

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    dispatch({
      type: "ADMIN_LOGOUT",
    });

    history.replace("/");
  };

  const renderTippyBox = (attrs) => {
    return (
      <div {...attrs} className="tippy-box-avatar">
        <ul className="ul-tippy">
          <li className="li-tippy" onClick={() => signOut()}>
            Đăng xuất
          </li>
        </ul>
      </div>
    );
  };

  return (
    <header className="header">
      <div className="left">
        <i className="fa-solid fa-bars" onClick={() => toggleSidebar()}></i>
        <img src={logo} alt="Shopping Online" />
        <a href="/">Shopping Online</a>
      </div>

      <div className="right">
        <Tippy
          interactive={true}
          render={(attrs) => {
            return renderTippyBox(attrs);
          }}
        >
          <img src={avatar} alt="Admin" />
        </Tippy>
      </div>
    </header>
  );
}
