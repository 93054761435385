const defaultState = {
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
};

export const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "ADMIN_LOGIN": {
      return {
        ...state,
        user: action.payload,
      };
    }
    case "ADMIN_LOGOUT": {
      return {
        ...state,
        user: {},
      };
    }
    default:
      return state;
  }
};
