import React, { useEffect, useState } from "react";
import { axiosService } from "../../util/service";
import { Select } from "antd";

export default function SubCategory({ currentCat, currentSubCat, idSP, getValueSubCategory }) {
  const [list, setList] = useState([]);
  const [listOption, setListOption] = useState([]);
  const [selected, setSelected] = useState(null);

  const getData = async () => {
    try {
      let response = await axiosService.get("api/ecommerce/getAllCategories");

      const thisCategory = response.data.data.find((item) => item.id == currentCat);
      setList(thisCategory.children);

      // mỗi lần đổi currentCat thì cập nhật lại selected là subcat đầu tiên luôn
      setSelected(thisCategory.children[0]?.id);
      getValueSubCategory(thisCategory.children[0]?.id, thisCategory.children[0]?.name);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [idSP, currentCat]);

  useEffect(() => {
    if (list.length > 0) {
      const temp = list.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      setListOption(temp);
    } else {
      setListOption([]);
    }
  }, [list]);

  useEffect(() => {
    setSelected(currentSubCat);
  }, [currentSubCat]);

  useEffect(() => {
    setSelected(currentSubCat);
  }, [idSP]);

  const handleChange = (value) => {
    setSelected(value);

    const temp = list.find((item) => item.id == value);
    getValueSubCategory(value, temp.name);
  };

  if (listOption.length == 0) {
    return <></>;
  }

  return (
    <Select
      value={selected}
      style={{
        width: "100%",
      }}
      onChange={handleChange}
      options={listOption}
    />
  );
}
