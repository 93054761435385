import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { axiosService } from "../../util/service";

export default function Dashboard() {
  const [statistics, setStatistics] = useState(null);
  const history = useHistory();

  const getStatistics = async () => {
    try {
      let response = await axiosService.get("api/admin/getStatistics");
      setStatistics(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  if (!statistics) return <></>;

  return (
    <div className="dashboard">
      <div className="title-area">
        <h2 className="title">Dashboard</h2>
      </div>

      <div className="statistics-zone">
        <div className="item item-1" onClick={() => history.push("/product")}>
          <span>Tổng số sản phẩm</span>
          <p>{statistics.totalProducts}</p>
        </div>
        <div className="item item-2" onClick={() => history.push("/user")}>
          <span>Tổng số người dùng</span>
          <p>{statistics.totalUsers}</p>
        </div>
        <div className="item item-3" onClick={() => history.push("/order")}>
          <span>Tổng số đơn hàng</span>
          <p>{statistics.totalOrders}</p>
        </div>
      </div>
    </div>
  );
}
