import React, { useEffect, useState } from "react";
import { axiosService } from "../../util/service";
import { Select } from "antd";

export default function Category({ currentCat, idSP, getValueCategory }) {
  const [list, setList] = useState([]);
  const [listOption, setListOption] = useState([]);
  const [selected, setSelected] = useState(null);

  const getData = async () => {
    try {
      let response = await axiosService.get("api/ecommerce/getAllCategories");
      setList(response.data.data);

      const temp = response.data.data.find((item) => item.id == currentCat);
      getValueCategory(currentCat, temp.name);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [idSP]);

  useEffect(() => {
    if (list.length > 0) {
      const temp = list.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      setListOption(temp);
    }
  }, [list]);

  useEffect(() => {
    setSelected(currentCat);
  }, [currentCat]);

  useEffect(() => {
    setSelected(currentCat);
  }, [idSP]);

  const handleChange = (value) => {
    setSelected(value);

    const temp = list.find((item) => item.id == value);

    getValueCategory(value, temp.name);
  };

  if (listOption.length == 0) {
    return <></>;
  }

  return (
    <Select
      value={selected}
      style={{
        width: "100%",
      }}
      onChange={handleChange}
      options={listOption}
    />
  );
}
