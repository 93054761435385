import React from "react";
import { useHistory } from "react-router-dom";

export default function Home() {
  const history = useHistory();

  if (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("user")).id === 1
  ) {
    history.replace("/dashboard");
  } else {
    history.replace("/login");
  }

  return <></>;
}
