// chuyển đổi chuỗi color, variant, promotion thành dạng JSON trước khi upload lên database
// VD: chuỗi red;green;blue --> ["red","green","blue"]
export const convertString = (string) => {
  if (!string) return "[]";

  const temp = string.split(";");

  let prefix = `[`;
  let subfix = `]`;
  let content = "";

  for (let index = 0; index < temp.length; index++) {
    const element = temp[index];
    if (index != temp.length - 1) {
      content += `"` + element.toString().trim() + `",`;
    } else {
      content += `"` + element.toString().trim() + `"`;
    }
  }

  return prefix + content + subfix;
};

// stringToSlug
export const stringToSlug = (str) => {
  var from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\-]/g, "-")
    .replace(/-+/g, "-");

  return str;
};

// nhận timestamp trả thời gian theo định dạng local string
export const convertTime = (timestamp) => {
  if (!timestamp) return "Chưa xác định";

  const time = Number(timestamp);
  const date = new Date(time);

  return date.toLocaleString();
};

// getPaymentMethod
export const getPaymentMethod = (string) => {
  switch (string) {
    case "cod": {
      return "Thanh toán tiền mặt khi nhận hàng (COD)";
    }
    case "momo": {
      return "Ví điện tử Momo";
    }
    case "zalopay": {
      return "Ví điện tử Zalo Pay";
    }
    case "shopeepay": {
      return "Ví điện tử Shopee Pay";
    }
    default: {
      return "Chưa xác định";
    }
  }
};
