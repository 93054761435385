import Swal from "sweetalert2";

const customClass = {
  confirmButton: "btn-swal-antd",
  htmlContainer: "content-swal-antd",
  icon: "no-border",
};

const swalSuccess = Swal.mixin({
  customClass,
  buttonsStyling: false,
  iconHtml: '<i class="fa-solid fa-circle-check"></i>',
  iconColor: "#52c41a",
});

const swalError = Swal.mixin({
  customClass,
  buttonsStyling: false,
  iconHtml: '<i class="fa-solid fa-circle-xmark"></i>',
  iconColor: "#ff4d4f",
});

const swalInfo = Swal.mixin({
  customClass,
  buttonsStyling: false,
  iconHtml: '<i class="fa-solid fa-circle-exclamation"></i>',
  iconColor: "#2095f2",
});

export const showAlert = (type, text) => {
  if (type === 1) {
    swalSuccess.fire({ title: null, text });
  } else if (type === 2) {
    swalError.fire({ title: null, text });
  } else if (type === 3) {
    swalInfo.fire({ title: null, text });
  }
};
