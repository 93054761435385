import { Button, Input, Pagination, Switch, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { ROWS } from "../../util/constant";
import { axiosService } from "../../util/service";
import { showAlert } from "../../util/showAlert";

export default function User() {
  // data
  const [listData, setListData] = useState([]);

  // pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getAllUsers(ROWS, page);
  };

  const activeUser = async (id) => {
    try {
      let response = await axiosService.post("api/admin/activeUser", { id });
      showAlert(1, response.data.message);
      getAllUsers(ROWS, current);
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const blockAndUnblockUser = async (id) => {
    try {
      let response = await axiosService.post("api/admin/blockAndUnblockUser", { id });
      showAlert(1, response.data.message);
      getAllUsers(ROWS, current);
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const onSwitchChange = (value, id) => {
    blockAndUnblockUser(id);
  };

  const columns = [
    {
      title: "ID",
      key: "ID",
      dataIndex: "id",
      width: 50,
      fixed: "left",
    },
    {
      title: "Username",
      key: "Username",
      dataIndex: "username",
      width: 100,
      fixed: "left",
    },
    {
      title: "Email",
      key: "Email",
      dataIndex: "email",
      width: 200,
    },
    {
      title: "Tên",
      key: "Tên",
      dataIndex: "full_name",
      width: 200,
    },
    {
      title: "Ngày sinh",
      key: "Ngày sinh",
      dataIndex: "birthday",
      width: 120,
    },
    {
      title: "Địa chỉ",
      key: "Địa chỉ",
      dataIndex: "address",
      width: 300,
    },
    {
      title: "Giới tính",
      key: "Giới tính",
      render: (_, { gender }) => {
        let x = gender == 0 ? "Nam" : "Nữ";
        return <span>{x}</span>;
      },
      width: 100,
    },
    {
      title: "SĐT",
      key: "SĐT",
      dataIndex: "phone",
      width: 120,
    },
    {
      title: "Điểm",
      key: "Điểm",
      dataIndex: "point",
      width: 80,
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: "Kích hoạt",
      key: "Kích hoạt",
      render: (_, { active, id }) => {
        // return <Switch size="small" defaultChecked={active == 0 ? false : true} />;
        if (active == 0) {
          return (
            <Button size="small" type="primary" onClick={() => activeUser(id)}>
              Kích hoạt
            </Button>
          );
        } else if (active == 1) {
          return (
            <Tag color="green" style={{ cursor: "pointer" }}>
              <i className="fa-sharp fa-solid fa-circle-check" style={{ marginRight: 5 }}></i>
              <b>Đã kích hoạt</b>
            </Tag>
          );
        }
      },
      width: 130,
    },
    {
      title: "Chặn",
      key: "Chặn",
      render: (_, { block, id }) => {
        if (id == 1) return <></>;

        return (
          <Switch
            size="small"
            defaultChecked={block == 0 ? false : true}
            style={{ backgroundColor: "red" }}
            onChange={(value) => onSwitchChange(value, id)}
          />
        );
      },
      width: 80,
    },
  ];

  const columnsMobile = columns.filter((item) => item.title != "ID");

  const getAllUsers = async (limit, page) => {
    try {
      let response = await axiosService.post("api/admin/getAllUsers", { limit, page });
      setListData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUsers(ROWS, 1);
    setCurrent(1);
  }, []);

  return (
    <div className="manage-user">
      <div className="title-area">
        <h2 className="title">Quản lý người dùng</h2>

        <Input.Search
          placeholder="Tìm kiếm người dùng"
          enterButton
          className="search-box"
          // allowClear
          // onSearch={onSearch}
          // onChange={onChangeKeyword}
        />

        <Pagination
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          className="pagination-box"
          pageSize={ROWS}
        />
      </div>

      <Table
        size="small"
        columns={window.innerWidth <= 576 ? columnsMobile : columns}
        dataSource={listData}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: 1700 }}
      />
    </div>
  );
}
