import { Button } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../assets/img/logo.png";
import { axiosService } from "../../util/service";
import { showAlert } from "../../util/showAlert";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Không được để trống").email("Email sai định dạng"),
      password: Yup.string().required("Không được để trống"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      login(values);
    },
  });

  const login = async (data) => {
    setLoading(true);

    try {
      let response = await axiosService.post("api/admin/login", data);

      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data));

      dispatch({
        type: "ADMIN_LOGIN",
        payload: response.data.data,
      });

      history.push("/");
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    showAlert(3, "Please contact your admin for more information");
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    document.getElementById("form-login")?.addEventListener("keypress", handleEnter);
  }, []);

  if (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("user")).id === 1
  ) {
    return history.replace("/dashboard");
  }

  return (
    <div className="login-component">
      <div className="left">
        <video src={"/video2.mp4"} autoPlay loop muted></video>
        <div className="overlay"></div>
      </div>

      <div className="right">
        <div className="top-left-corner">
          <img src={logo} alt="Shopping Online" />
          <a href="/" className="brand-name">
            Shopping Online | Administrator
          </a>
        </div>

        <div className="right-center">
          <h2 className="title">đăng nhập</h2>

          <form id="form-login">
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Địa chỉ email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <span className="span-error">{formik.errors.email}</span>

            <input
              type="password"
              id="password"
              name="password"
              placeholder="Mật khẩu"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <span className="span-error">{formik.errors.password}</span>

            <Button
              id="btn-login"
              onClick={formik.handleSubmit}
              type="primary"
              size="large"
              style={{ marginTop: 30, marginBottom: 30 }}
              loading={loading}
            >
              Đăng nhập
            </Button>
          </form>

          <a className="forgot-password" onClick={() => handleForgotPassword()}>
            Quên mật khẩu?
          </a>
        </div>
      </div>
    </div>
  );
}
