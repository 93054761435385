import { QuestionCircleFilled } from "@ant-design/icons";
import { Button, Drawer, Image, Input, Modal, Table } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { stringToSlug } from "../../util/function";
import { axiosService, DOMAIN } from "../../util/service";
import { showAlert } from "../../util/showAlert";
import SubCat from "./SubCat";

export default function Category() {
  const [listMenu, setListMenu] = useState([]);

  // drawer
  const [open, setOpen] = useState(false);

  const showDrawer = (record) => {
    setOpen(true);

    fm.setFieldValue("id", record.id);
    fm.setFieldValue("name", record.name);
    fm.setFieldValue("url", record.slug);
    fm.setFieldValue("time", record.created_at);
    fm.setFieldValue("img", record.img);
  };
  const onClose = () => {
    setOpen(false);
  };
  // end drawer

  // drawer 2
  const [open2, setOpen2] = useState(false);
  const showDrawer2 = () => {
    setOpen2(true);
    fm2.setFieldValue("name2", "");
    fm2.setFieldValue("url2", "");
    fm2.setFieldValue("img2", "");
    const inputElement = document.getElementById("input-file-img-2");
    inputElement.value = "";
  };
  const onClose2 = () => setOpen2(false);
  // end drawer 2

  const fm = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: "",
      url: "",
      time: "",
      img: "",
    },
    onSubmit: (v) => {
      editCategory({
        id: v.id,
        name: v.name,
        slug: v.url,
        img: v.img,
      });
    },
  });

  const fm2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      name2: "",
      url2: "",
      img2: "",
    },
    onSubmit: (v) => {
      addCategory({
        name: v.name2,
        slug: v.url2,
        img: v.img2,
      });
    },
  });

  const editCategory = async (data) => {
    try {
      let response = await axiosService.post("api/admin/editCategory", data);
      showAlert(1, response.data.message);
      onClose();
      getData();
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const deleteCategory = async (id) => {
    try {
      let response = await axiosService.post("api/admin/deleteCategory", { id });
      showAlert(1, response.data.message);
      getData();
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const addCategory = async (data) => {
    try {
      let response = await axiosService.post("api/admin/addCategory", data);
      showAlert(1, response.data.message);
      onClose2();
      getData();
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const getData = async () => {
    try {
      let response = await axiosService.get("api/ecommerce/getAllCategories");
      const temp = response.data.data;
      for (let item of temp) {
        delete item.children;
      }
      setListMenu(temp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const confirmDelete = (id, name) => {
    Modal.confirm({
      title: "Xác nhận xoá menu",
      icon: <QuestionCircleFilled />,
      content: (
        <span>
          Bạn có muốn xoá menu <b>{name}</b> không?
        </span>
      ),
      okText: "Xoá",
      onOk: () => deleteCategory(id),
    });
  };

  const columns = [
    {
      title: "ID",
      key: "ID",
      dataIndex: "id",
      width: 50,
    },
    {
      title: "Menu",
      key: "Menu",
      dataIndex: "name",
      width: 200,
    },
    {
      title: "URL",
      key: "URL",
      dataIndex: "slug",
      width: 200,
    },
    {
      title: "Hình ảnh",
      key: "Hình ảnh",
      render: (_, { img, name }) => {
        return <Image src={img} alt={name} className="img-in-table" />;
      },
      width: 100,
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: "Hành động",
      key: "Hành động",
      width: 150,
      render: (_, record) => {
        return (
          <>
            <Button type="primary" size="small" onClick={() => showDrawer(record)}>
              Xem & Sửa
            </Button>
            <br />
            <Button
              type="primary"
              size="small"
              danger
              style={{ marginTop: 4 }}
              onClick={() => confirmDelete(record.id, record.name)}
            >
              Xoá
            </Button>
          </>
        );
      },
      width: 200,
    },
  ];

  const generateURL = () => {
    const source = fm.values.name;
    if (!source) {
      showAlert(2, "Nhập tên menu trước");
      return;
    }

    const result = stringToSlug(source);
    fm.setFieldValue("url", result);
  };

  const generateURL2 = () => {
    const source = fm2.values.name2;
    if (!source) {
      showAlert(2, "Nhập tên menu trước");
      return;
    }

    const result = stringToSlug(source);
    fm2.setFieldValue("url2", result);
  };

  // nhóm hàm xử lý hình ảnh
  const uploadImage = async (img, formikField, isFormik2) => {
    try {
      let fd = new FormData();
      fd.append("image", img);

      let response = await axiosService.post("api/admin/uploadImage", fd);

      if (isFormik2) {
        fm2.setFieldValue(formikField, DOMAIN + "images/" + response.data.data.filename);
      } else {
        fm.setFieldValue(formikField, DOMAIN + "images/" + response.data.data.filename);
      }

      showAlert(1, response.data.message);
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.data.message);
    }
  };

  const handleUploadImage = (inputID, formikField, isFormik2) => {
    // isFormik2 để xác định hàm được gọi từ drawer 2

    const files = document.getElementById(inputID).files;
    if (!files || files.length == 0) {
      showAlert(2, "Chưa chọn hình ảnh");
      return;
    }
    const file = files[0];
    uploadImage(file, formikField, isFormik2);
  };

  const handleClearImage = (inputID, formikField, isFormik2) => {
    if (isFormik2) {
      fm2.setFieldValue(formikField, "");
    } else {
      fm.setFieldValue(formikField, "");
    }

    const inputElement = document.getElementById(inputID);
    inputElement.value = "";
  };

  const handleSeeImage = (formikField, isFormik2) => {
    if (isFormik2) {
      if (!fm2.values[formikField]) {
        showAlert(2, "Không tìm thấy hình ảnh");
        return;
      }
      window.open(fm2.values[formikField]);
    } else {
      if (!fm.values[formikField]) {
        showAlert(2, "Không tìm thấy hình ảnh");
        return;
      }
      window.open(fm.values[formikField]);
    }
  };
  // end - nhóm hàm xử lý hình ảnh

  return (
    <>
      <div className="category-component">
        <div className="title-area">
          <h2 className="title">Quản lý menu</h2>

          <div className="pagination-box">
            <Button type="primary" onClick={showDrawer2}>
              <i className="fa-solid fa-plus" style={{ marginRight: 5 }}></i>
              Tạo menu
            </Button>
          </div>
        </div>

        <Table
          size="small"
          columns={columns}
          dataSource={listMenu}
          rowKey={(record) => record.id}
          pagination={false}
          scroll={{ x: 800 }}
        />

        <Drawer
          title="Chi tiết menu"
          placement="right"
          onClose={onClose}
          open={open}
          width={window.innerWidth <= 800 ? "100%" : 800}
          extra={
            <Button type="primary" onClick={() => fm.handleSubmit()}>
              Lưu thay đổi
            </Button>
          }
        >
          <div className="drawer-content menu">
            <form>
              <div className="field id-field">
                <label htmlFor="id">ID</label>
                <Input id="id" className="id" name="id" value={fm.values.id} disabled />
              </div>

              <div className="field name-field">
                <label htmlFor="name">Tên menu</label>
                <Input id="name" className="name" name="name" value={fm.values.name} onChange={fm.handleChange} />
              </div>

              <div className="field url-field">
                <label htmlFor="url">URL</label>
                <Input id="url" className="url" name="url" value={fm.values.url} onChange={fm.handleChange} />
                <Button style={{ marginLeft: 8 }} onClick={() => generateURL()}>
                  Generate
                </Button>
              </div>

              <div className="field time-field">
                <label htmlFor="time">Thời gian</label>
                <Input id="time" className="time" name="time" value={fm.values.time} disabled />
              </div>

              <div className="field img-field">
                <label htmlFor="img">Hình ảnh</label>

                <div className="upload-img-field">
                  <input type="file" className="input-file" id="input-file-img" />

                  <div className="btn-groups">
                    <Button size="small" onClick={() => handleUploadImage("input-file-img", "img")}>
                      Upload
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleClearImage("input-file-img", "img")}
                      style={{ marginLeft: 15 }}
                    >
                      Xoá
                    </Button>
                    <Button size="small" onClick={() => handleSeeImage("img")} style={{ marginLeft: 15 }}>
                      Xem
                    </Button>
                  </div>

                  <Input disabled id="img" className="img" name="img" value={fm.values.img} />
                </div>
              </div>
            </form>
          </div>
        </Drawer>

        <Drawer
          title="Tạo menu mới"
          placement="right"
          onClose={onClose2}
          open={open2}
          width={window.innerWidth <= 800 ? "100%" : 800}
          extra={
            <Button type="primary" onClick={() => fm2.handleSubmit()}>
              Tạo menu
            </Button>
          }
        >
          <div className="drawer-content menu">
            <form>
              <div className="field name2-field">
                <label htmlFor="name2">Tên menu</label>
                <Input id="name2" className="name2" name="name2" value={fm2.values.name2} onChange={fm2.handleChange} />
              </div>

              <div className="field url2-field">
                <label htmlFor="url2">URL</label>
                <Input id="url2" className="url2" name="url2" value={fm2.values.url2} onChange={fm2.handleChange} />
                <Button style={{ marginLeft: 8 }} onClick={() => generateURL2()}>
                  Generate
                </Button>
              </div>

              <div className="field img2-field">
                <label htmlFor="img2">Hình ảnh</label>

                <div className="upload-img-field">
                  <input type="file" className="input-file" id="input-file-img-2" />

                  <div className="btn-groups">
                    <Button size="small" onClick={() => handleUploadImage("input-file-img-2", "img2", true)}>
                      Upload
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleClearImage("input-file-img-2", "img2", true)}
                      style={{ marginLeft: 15 }}
                    >
                      Xoá
                    </Button>
                    <Button size="small" onClick={() => handleSeeImage("img2", true)} style={{ marginLeft: 15 }}>
                      Xem
                    </Button>
                  </div>

                  <Input disabled id="img2" className="img2" name="img2" value={fm2.values.img2} />
                </div>
              </div>
            </form>
          </div>
        </Drawer>
      </div>

      <div className="divider" style={window.innerWidth <= 576 ? { marginBottom: 10 } : { marginBottom: 30 }}></div>

      <SubCat />
    </>
  );
}
