import { QuestionCircleFilled } from "@ant-design/icons";
import { Button, Drawer, Image, Input, Modal, Pagination, Table } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { DOMAIN, ROWS } from "../../util/constant";
import { convertString } from "../../util/function";
import { axiosService } from "../../util/service";
import { showAlert } from "../../util/showAlert";
import Category from "./Category";
import SubCategory from "./SubCategory";

const { Search, TextArea } = Input;

export default function Product() {
  // data
  const [listData, setListData] = useState([]);

  // keyword
  const [keyword, setKeyword] = useState("");

  // pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");

  // drawer
  const [open, setOpen] = useState(false);

  const showDrawer = (record) => {
    setOpen(true);

    // set selected record to formik
    formik.setFieldValue("id", record.id);
    formik.setFieldValue("sku", record.sku);
    formik.setFieldValue("time", record.created_at);
    formik.setFieldValue("name", record.name);
    formik.setFieldValue("price", record.price);
    formik.setFieldValue("isDiscount", record.is_discount == 1 ? true : false);
    formik.setFieldValue("discountPrice", record.discount_price == null ? null : record.discount_price);
    formik.setFieldValue("rating", record.rating);
    formik.setFieldValue("totalRate", record.total_rate);
    formik.setFieldValue("isBestSeller", record.is_best_seller == 1 ? true : false);
    formik.setFieldValue("isNew", record.is_new == 1 ? true : false);
    formik.setFieldValue("category", record.category_id);
    formik.setFieldValue("subCategory", record.sub_category_id);
    formik.setFieldValue("stock", record.stock);
    formik.setFieldValue(
      "color",
      Array.isArray(record.color) ? record.color.join(";") : JSON.parse(record.color).join(";")
    );
    formik.setFieldValue(
      "variant",
      Array.isArray(record.variant) ? record.variant.join(";") : JSON.parse(record.variant).join(";")
    );
    formik.setFieldValue(
      "promotion",
      Array.isArray(record.promotion) ? record.promotion.join(";") : JSON.parse(record.promotion).join(";")
    );
    formik.setFieldValue("detail", record.detail);
    formik.setFieldValue("material", record.material);
    formik.setFieldValue("guide", record.guide);
    formik.setFieldValue("img1", record.img1);
    formik.setFieldValue("img2", record.img2);
    formik.setFieldValue("img3", record.img3);
    formik.setFieldValue("img4", record.img4);
  };
  const onClose = () => {
    setOpen(false);
  };
  // end drawer

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      category: 0,
      subCategory: 0,
      sku: "",
      time: "",
      name: "",
      price: "",
      isDiscount: false,
      discountPrice: null,
      rating: "",
      totalRate: "",
      isBestSeller: false,
      isNew: false,
      stock: "",
      color: "",
      variant: "",
      promotion: "",
      detail: "",
      material: "",
      guide: "",
      img1: "",
      img2: "",
      img3: "",
      img4: "",
    },
    onSubmit: (v) => {
      if (!v.category || !v.subCategory) {
        showAlert(2, "Chưa chọn danh mục");
        return;
      }

      let priceNum = Number(v.price);
      if (priceNum <= 0 || isNaN(priceNum) || !Number.isInteger(priceNum)) {
        showAlert(2, "Kiểm tra lại giá bán");
        return;
      }

      if (v.discountPrice != null && Number(v.discountPrice) >= Number(v.price)) {
        showAlert(2, "Giá giảm phải nhỏ hơn giá gốc");
        return;
      }

      let stockNum = Number(v.stock);
      if (stockNum <= 0 || isNaN(stockNum) || !Number.isInteger(stockNum)) {
        showAlert(2, "Kiểm tra lại số lượng tồn kho");
        return;
      }

      if (!v.img1 || !v.img2 || !v.img3 || !v.img4) {
        showAlert(2, "Chưa chọn hình ảnh");
        return;
      }

      editProduct({
        id: Number(v.id),
        sku: v.sku,
        name: v.name,
        price: Number(v.price),
        is_discount: v.isDiscount ? "1" : "0", // 1 hoặc 0 dạng chuỗi để tránh bị empty khi validation
        discount_price: v.discountPrice == null ? "null" : Number(v.discountPrice), // string null để tránh bị empty khi validation
        is_best_seller: v.isBestSeller ? "1" : "0",
        is_new: v.isNew ? "1" : "0",
        category_id: Number(v.category),
        category_name: name1,
        sub_category_id: Number(v.subCategory),
        sub_category_name: name2,
        stock: Number(v.stock),
        color: convertString(v.color),
        variant: convertString(v.variant),
        promotion: convertString(v.promotion),
        detail: v.detail,
        material: v.material,
        guide: v.guide,
        img1: v.img1,
        img2: v.img2,
        img3: v.img3,
        img4: v.img4,
      });
    },
  });

  const getAllProducts = async (limit, page, nextSearch) => {
    // nextSearch = true: sau khi getAllProducts tiếp tục tìm kiếm với keyword
    // mặc định gọi hàm mà không truyền thì nextSearch undefined

    try {
      let response = await axiosService.post("api/ecommerce/getAllProducts", {
        limit,
        page,
      });
      setListData(response.data.data.array);
      setTotalRecord(response.data.data.total);

      if (nextSearch) {
        if (keyword) {
          searchProduct(keyword);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "ID",
      key: "ID",
      dataIndex: "id",
      width: 40,
    },
    {
      title: "SKU",
      key: "SKU",
      dataIndex: "sku",
      width: 80,
    },
    {
      title: "Tên sản phẩm",
      key: "Tên sản phẩm",
      dataIndex: "name",
      width: 320,
    },
    {
      title: "Hình ảnh",
      key: "Hình ảnh",
      width: 80,
      render: (_, { img1, sku }) => {
        return <Image src={img1} alt={sku} className="img1-in-table" />;
      },
    },
    {
      title: <span>Giá (VNĐ)</span>,
      key: "Giá",
      width: 150,
      render: (_, record) => {
        if (record.is_discount == 1 && record.discount_price != null) {
          const x = record.price - record.discount_price;
          const percent = (x / record.price) * 100;

          return (
            <div>
              <span className="old-price">{record.price.toLocaleString()}</span> <br />
              <span className="new-price">
                <span className="discount-percent">
                  <i className="fa-solid fa-down-long"></i>
                  {percent.toFixed(0)}%
                </span>
                = {record.discount_price.toLocaleString()}
              </span>
            </div>
          );
        } else {
          return <span className="price">{record.price.toLocaleString()}</span>;
        }
      },
    },
    {
      title: "Đánh giá",
      key: "Đánh giá",
      width: 150,
      render: (_, { rating, total_rate }) => {
        return (
          <div>
            <span>
              <i className="fa-solid fa-star" style={{ color: "orange" }}></i> {rating}
            </span>{" "}
            <br />
            <span>{total_rate} đánh giá</span>
          </div>
        );
      },
    },
    {
      title: "Hành động",
      key: "Hành động",
      width: 150,
      render: (_, record) => {
        return (
          <>
            <Button type="primary" size="small" onClick={() => showDrawer(record)}>
              Xem & Sửa
            </Button>
            <br />
            <Button
              type="primary"
              size="small"
              danger
              style={{ marginTop: 4 }}
              onClick={() => confirmDelete(record.id, record.name)}
            >
              Xoá
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getAllProducts(ROWS, 1, false);
  }, []);

  const onChangeKeyword = (e) => {
    const k = e.target.value;
    setKeyword(k);

    if (!k) {
      setKeyword("");
      getAllProducts(ROWS, 1, false);
      setCurrent(1);
    }
  };

  const onSearch = () => {
    // hàm này gọi khi: enter, nhấn nút search, hoặc clear bằng nút x (allowClear)
    // nếu có keyword thì search, không có thì getAllProducts
    if (keyword && keyword.length > 0) {
      searchProduct(keyword);
    } else {
      getAllProducts(ROWS, 1, false);
    }
  };

  const onPaginationChange = (page) => {
    setCurrent(page);
    getAllProducts(ROWS, page, false);
  };

  const getValueCategory = (cat, name) => {
    // truyền vào trong component Category để lấy data bên trong ra
    formik.setFieldValue("category", cat);
    setName1(name);
  };

  const getValueSubCategory = (subcat, name) => {
    // truyền vào trong component SubCategory để lấy data bên trong ra
    formik.setFieldValue("subCategory", subcat);
    setName2(name);
  };

  const handleCheckboxChange = (e) => {
    formik.setFieldValue("isDiscount", e.target.checked);
    if (e.target.checked == false) {
      formik.setFieldValue("discountPrice", null);
    }
  };

  const confirmDelete = (id, name) => {
    Modal.confirm({
      title: "Xác nhận xoá sản phẩm",
      icon: <QuestionCircleFilled />,
      content: (
        <span>
          Bạn có muốn xoá sản phẩm <b>{name}</b> không?
        </span>
      ),
      okText: "Xoá",
      onOk: () => deleteProduct(id),
    });
  };

  const deleteProduct = async (id) => {
    try {
      let response = await axiosService.post("api/admin/deleteProduct", { id });
      showAlert(1, response.data.message);
      getAllProducts(ROWS, current, true);
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const uploadImage = async (img, formikField) => {
    try {
      let fd = new FormData();
      fd.append("image", img);

      let response = await axiosService.post("api/admin/uploadImage", fd);
      formik.setFieldValue(formikField, DOMAIN + "images/" + response.data.data.filename);
      showAlert(1, response.data.message);
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.data.message);
    }
  };

  const handleUploadImage = (inputID, formikField) => {
    const files = document.getElementById(inputID).files;
    if (!files || files.length == 0) {
      showAlert(2, "Chưa chọn hình ảnh");
      return;
    }
    const file = files[0];
    uploadImage(file, formikField);
  };

  const handleClearImage = (inputID, formikField) => {
    formik.setFieldValue(formikField, "");
    const inputElement = document.getElementById(inputID);
    inputElement.value = "";
  };

  const handleSeeImage = (formikField) => {
    if (!formik.values[formikField]) {
      showAlert(2, "Không tìm thấy hình ảnh");
      return;
    }
    window.open(formik.values[formikField]);
  };

  const editProduct = async (data) => {
    try {
      let response = await axiosService.post("api/admin/editProduct", data);
      showAlert(1, response.data.message);
      onClose();
      getAllProducts(ROWS, current, true);
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const searchProduct = async (keyword) => {
    try {
      let response = await axiosService.post("api/admin/searchProduct", { keyword });
      setListData(response.data.data.array);
      setTotalRecord(response.data.data.total);
      setCurrent(1);
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  return (
    <div className="product-component">
      <div className="title-area">
        <h2 className="title">Danh sách sản phẩm</h2>

        <Search
          placeholder="Tìm kiếm sản phẩm"
          enterButton
          className="search-box"
          // allowClear
          onSearch={onSearch}
          onChange={onChangeKeyword}
        />

        <Pagination
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          className="pagination-box"
          pageSize={ROWS}
        />
      </div>

      <Table
        size="small"
        columns={columns}
        dataSource={listData}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: 1000 }}
      />

      <Drawer
        title="Chi tiết sản phẩm"
        placement="right"
        onClose={onClose}
        open={open}
        width={window.innerWidth <= 800 ? "100%" : 800}
        extra={
          <Button type="primary" onClick={formik.handleSubmit}>
            Lưu thay đổi
          </Button>
        }
      >
        <div className="drawer-content product">
          <form>
            <div className="field id-field">
              <label htmlFor="id">ID</label>
              <Input id="id" className="id" name="id" value={formik.values.id} disabled />
            </div>

            <div className="field sku-field">
              <label htmlFor="sku">SKU</label>
              <Input id="sku" className="sku" name="sku" value={formik.values.sku} onChange={formik.handleChange} />
            </div>

            <div className="field time-field">
              <label htmlFor="time">Thời gian</label>
              <Input id="time" className="time" name="time" value={formik.values.time} disabled />
            </div>

            <div className="field name-field">
              <label htmlFor="name">Tên SP</label>
              <Input id="name" className="name" name="name" value={formik.values.name} onChange={formik.handleChange} />
            </div>

            <div className="field price-field">
              <label htmlFor="price">Giá</label>
              <Input
                id="price"
                className="price"
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field isDiscount-field">
              <label htmlFor="isDiscount">Giảm giá?</label>
              <input
                type={"checkbox"}
                id="isDiscount"
                className="isDiscount"
                name="isDiscount"
                checked={formik.values.isDiscount}
                onChange={handleCheckboxChange}
              />
            </div>

            {formik.values.isDiscount == true ? (
              <div className="field discountPrice-field">
                <label htmlFor="discountPrice">Giá sau giảm</label>
                <Input
                  id="discountPrice"
                  className="discountPrice"
                  name="discountPrice"
                  value={formik.values.discountPrice}
                  onChange={formik.handleChange}
                />
              </div>
            ) : null}

            <div className="field rating-field">
              <label htmlFor="rating">Đánh giá</label>
              <Input id="rating" className="rating" name="rating" value={formik.values.rating} disabled />
            </div>

            <div className="field totalRate-field">
              <label htmlFor="totalRate">Số lượt</label>
              <Input id="totalRate" className="totalRate" name="totalRate" value={formik.values.totalRate} disabled />
            </div>

            <div className="field isBestSeller-field">
              <label htmlFor="isBestSeller">Best seller?</label>
              <input
                type={"checkbox"}
                id="isBestSeller"
                className="isBestSeller"
                name="isBestSeller"
                checked={formik.values.isBestSeller}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field isNew-field">
              <label htmlFor="isNew">Mới?</label>
              <input
                type={"checkbox"}
                id="isNew"
                className="isNew"
                name="isNew"
                checked={formik.values.isNew}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field category-field">
              <label htmlFor="category">Danh mục</label>
              <Category
                currentCat={formik.values.category}
                idSP={formik.values.id}
                getValueCategory={getValueCategory}
              />
            </div>

            <div className="field subcategory-field">
              <label htmlFor="subcategory">Danh mục con</label>
              <SubCategory
                currentCat={formik.values.category}
                currentSubCat={formik.values.subCategory}
                idSP={formik.values.id}
                getValueSubCategory={getValueSubCategory}
              />
            </div>

            <div className="field stock-field">
              <label htmlFor="stock">Tồn kho</label>
              <Input
                id="stock"
                className="stock"
                name="stock"
                value={formik.values.stock}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field color-field">
              <label htmlFor="color">Màu sắc</label>
              <Input
                placeholder="Nhập tên màu cách nhau bởi dấu chấm phẩy"
                id="color"
                className="color"
                name="color"
                value={formik.values.color}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field variant-field">
              <label htmlFor="variant">Lựa chọn</label>
              <Input
                placeholder="Nhập các lựa chọn cách nhau bởi dấu chấm phẩy"
                id="variant"
                className="variant"
                name="variant"
                value={formik.values.variant}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field promotion-field">
              <label htmlFor="promotion">Khuyến mãi</label>
              <Input
                placeholder="Nhập các khuyến mãi cách nhau bởi dấu chấm phẩy"
                id="promotion"
                className="promotion"
                name="promotion"
                value={formik.values.promotion}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field detail-field">
              <label htmlFor="detail">Chi tiết</label>
              <TextArea
                rows={3}
                id="detail"
                className="detail"
                name="detail"
                value={formik.values.detail}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field material-field">
              <label htmlFor="material">Chất liệu</label>
              <TextArea
                rows={3}
                id="material"
                className="material"
                name="material"
                value={formik.values.material}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field guide-field">
              <label htmlFor="guide">Hướng dẫn</label>
              <TextArea
                rows={3}
                id="guide"
                className="guide"
                name="guide"
                value={formik.values.guide}
                onChange={formik.handleChange}
              />
            </div>

            <div className="field img1-field">
              <label htmlFor="img1">Hình ảnh 1</label>

              <div className="upload-img-field">
                <input type="file" className="input-file" id="input-file-img1" />

                <div className="btn-groups">
                  <Button size="small" onClick={() => handleUploadImage("input-file-img1", "img1")}>
                    Upload
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleClearImage("input-file-img1", "img1")}
                    style={{ marginLeft: 15 }}
                  >
                    Xoá
                  </Button>
                  <Button size="small" onClick={() => handleSeeImage("img1")} style={{ marginLeft: 15 }}>
                    Xem
                  </Button>
                </div>

                <Input
                  disabled
                  id="img1"
                  className="img1"
                  name="img1"
                  value={formik.values.img1}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="field img2-field">
              <label htmlFor="img2">Hình ảnh 2</label>

              <div className="upload-img-field">
                <input type="file" className="input-file" id="input-file-img2" />

                <div className="btn-groups">
                  <Button size="small" onClick={() => handleUploadImage("input-file-img2", "img2")}>
                    Upload
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleClearImage("input-file-img2", "img2")}
                    style={{ marginLeft: 15 }}
                  >
                    Xoá
                  </Button>
                  <Button size="small" onClick={() => handleSeeImage("img2")} style={{ marginLeft: 15 }}>
                    Xem
                  </Button>
                </div>

                <Input
                  disabled
                  id="img2"
                  className="img2"
                  name="img2"
                  value={formik.values.img2}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="field img3-field">
              <label htmlFor="img3">Hình ảnh 3</label>

              <div className="upload-img-field">
                <input type="file" className="input-file" id="input-file-img3" />

                <div className="btn-groups">
                  <Button size="small" onClick={() => handleUploadImage("input-file-img3", "img3")}>
                    Upload
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleClearImage("input-file-img3", "img3")}
                    style={{ marginLeft: 15 }}
                  >
                    Xoá
                  </Button>
                  <Button size="small" onClick={() => handleSeeImage("img3")} style={{ marginLeft: 15 }}>
                    Xem
                  </Button>
                </div>

                <Input
                  disabled
                  id="img3"
                  className="img3"
                  name="img3"
                  value={formik.values.img3}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="field img4-field">
              <label htmlFor="img4">Hình ảnh 4</label>

              <div className="upload-img-field">
                <input type="file" className="input-file" id="input-file-img4" />

                <div className="btn-groups">
                  <Button size="small" onClick={() => handleUploadImage("input-file-img4", "img4")}>
                    Upload
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleClearImage("input-file-img4", "img4")}
                    style={{ marginLeft: 15 }}
                  >
                    Xoá
                  </Button>
                  <Button size="small" onClick={() => handleSeeImage("img4")} style={{ marginLeft: 15 }}>
                    Xem
                  </Button>
                </div>

                <Input
                  disabled
                  id="img4"
                  className="img4"
                  name="img4"
                  value={formik.values.img4}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
}
