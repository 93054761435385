import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import Category from "./components/Category/Category";
import Dashboard from "./components/Dashboard/Dashboard";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Order from "./components/Order/Order";
import AddProduct from "./components/Product/AddProduct";
import Product from "./components/Product/Product";
import User from "./components/User/User";
import Image from "./components/Image/Image";
import AdminTemplate from "./templates/AdminTemplate";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <AdminTemplate path="/dashboard" component={Dashboard} />
        <AdminTemplate path="/product" component={Product} />
        <AdminTemplate path="/add-product" component={AddProduct} />
        <AdminTemplate path="/user" component={User} />
        <AdminTemplate path="/category" component={Category} />
        <AdminTemplate path="/order" component={Order} />
        <AdminTemplate path="/image" component={Image} />

        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
