import { QuestionCircleFilled } from "@ant-design/icons";
import { Button, Drawer, Input, Modal, Select, Table } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { stringToSlug } from "../../util/function";
import { axiosService } from "../../util/service";
import { showAlert } from "../../util/showAlert";

export default function SubCat() {
  const [listData, setListData] = useState([]);
  const [listSubData, setListSubData] = useState([]);
  const [selected, setSelected] = useState(1);

  // drawer
  const [open, setOpen] = useState(false);
  const showDrawer = (record) => {
    setOpen(true);

    const cat = listData.find((cat) => cat.id == record.category_id);

    fm.setFieldValue("id", record.id);
    fm.setFieldValue("name", record.name);
    fm.setFieldValue("url", record.slug);
    fm.setFieldValue("time", record.created_at);
    fm.setFieldValue("catID", record.category_id);
    fm.setFieldValue("catName", cat.name ?? "");
  };
  const onClose = () => setOpen(false);
  const fm = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: "",
      url: "",
      time: "",
      catID: "",
      catName: "",
    },
    onSubmit: (v) => {
      editSubCategory({
        id: v.id,
        name: v.name,
        slug: v.url,
        catID: v.catID,
        catName: v.catName,
      });
    },
  });
  // end drawer

  // drawer 2
  const [open2, setOpen2] = useState(false);
  const showDrawer2 = () => {
    setOpen2(true);

    fm2.setFieldValue("name2", "");
    fm2.setFieldValue("url2", "");
    fm2.setFieldValue("catID2", "");
  };
  const onClose2 = () => setOpen2(false);
  const fm2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      name2: "",
      url2: "",
      catID2: "",
    },
    onSubmit: (v) => {
      if (!v.name2) {
        showAlert(2, "Chưa nhập tên submenu");
        return;
      }

      if (!v.url2) {
        showAlert(2, "Chưa nhập URL");
        return;
      }

      if (!v.catID2) {
        showAlert(2, "Chưa chọn danh mục");
        return;
      }

      addSubCategory({
        name: v.name2,
        slug: v.url2,
        catID: v.catID2,
      });
    },
  });
  // end drawer 2

  const getData = async () => {
    try {
      let response = await axiosService.get("api/ecommerce/getAllCategories");
      setListData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const editSubCategory = async (data) => {
    try {
      let response = await axiosService.post("api/admin/editSubCategory", data);
      showAlert(1, response.data.message);
      onClose();
      getData();
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const addSubCategory = async (data) => {
    try {
      let response = await axiosService.post("api/admin/addSubCategory", data);
      showAlert(1, response.data.message);
      onClose2();
      getData();
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  const deleteSubCategory = async (id) => {
    try {
      let response = await axiosService.post("api/admin/deleteSubCategory", { id });
      showAlert(1, response.data.message);
      getData();
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.message);
    }
  };

  useEffect(() => {
    if (listData.length != 0) {
      const subList = listData.find((item) => item.id == selected);
      setListSubData(subList.children);
      console.log(subList.children);
    }
  }, [listData, selected]);

  const handleSelectChange = (value) => {
    setSelected(value);
  };

  const confirmDelete = (id, name) => {
    Modal.confirm({
      title: "Xác nhận xoá menu",
      icon: <QuestionCircleFilled />,
      content: (
        <span>
          Bạn có muốn xoá menu <b>{name}</b> không?
        </span>
      ),
      okText: "Xoá",
      onOk: () => deleteSubCategory(id),
    });
  };

  const columns = [
    {
      title: "ID",
      key: "ID",
      dataIndex: "id",
      width: 50,
    },
    {
      title: "Submenu",
      key: "Menu",
      dataIndex: "name",
      width: 200,
    },
    {
      title: "URL",
      key: "URL",
      dataIndex: "slug",
      width: 200,
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: "Hành động",
      key: "Hành động",
      width: 150,
      render: (_, record) => {
        return (
          <>
            <Button type="primary" size="small" onClick={() => showDrawer(record)}>
              Xem & Sửa
            </Button>
            <br />
            <Button
              type="primary"
              size="small"
              danger
              style={{ marginTop: 4 }}
              onClick={() => confirmDelete(record.id, record.name)}
            >
              Xoá
            </Button>
          </>
        );
      },
      width: 200,
    },
  ];

  const generateURL = () => {
    const source = fm.values.name;
    if (!source) {
      showAlert(2, "Nhập tên menu trước");
      return;
    }

    const result = stringToSlug(source);
    fm.setFieldValue("url", result);
  };

  const generateURL2 = () => {
    const source = fm2.values.name2;
    if (!source) {
      showAlert(2, "Nhập tên menu trước");
      return;
    }

    const result = stringToSlug(source);
    fm2.setFieldValue("url2", result);
  };

  return (
    <div className="subcat-component">
      <div className="title-area">
        <h2 className="title">Quản lý menu con</h2>

        <div className="search-box">
          {listData.length != 0 && (
            <Select
              defaultValue={selected}
              style={window.innerWidth <= 576 ? { width: "100%" } : { width: 250 }}
              onChange={handleSelectChange}
              options={listData.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
            />
          )}
        </div>

        <div className="pagination-box">
          <Button type="primary" onClick={showDrawer2}>
            <i className="fa-solid fa-plus" style={{ marginRight: 5 }}></i>
            Tạo menu
          </Button>
        </div>
      </div>

      <Table
        size="small"
        columns={columns}
        dataSource={listSubData}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: 800 }}
      />

      <Drawer
        title="Chi tiết submenu"
        placement="right"
        onClose={onClose}
        open={open}
        width={window.innerWidth <= 800 ? "100%" : 800}
        extra={
          <Button type="primary" onClick={() => fm.handleSubmit()}>
            Lưu thay đổi
          </Button>
        }
      >
        <div className="drawer-content menu">
          <form>
            <div className="field id-field">
              <label htmlFor="id">ID</label>
              <Input id="id" className="id" name="id" value={fm.values.id} disabled />
            </div>

            <div className="field name-field">
              <label htmlFor="name">Tên menu</label>
              <Input id="name" className="name" name="name" value={fm.values.name} onChange={fm.handleChange} />
            </div>

            <div className="field url-field">
              <label htmlFor="url">URL</label>
              <Input id="url" className="url" name="url" value={fm.values.url} onChange={fm.handleChange} />
              <Button style={{ marginLeft: 8 }} onClick={() => generateURL()}>
                Generate
              </Button>
            </div>

            <div className="field time-field">
              <label htmlFor="time">Thời gian</label>
              <Input id="time" className="time" name="time" value={fm.values.time} disabled />
            </div>

            <div className="field catID-field">
              <label htmlFor="catID">Danh mục</label>
              <Select
                id="catID"
                className="catID"
                name="catID"
                defaultValue={fm.values.catID}
                value={fm.values.catID}
                style={{ width: "100%" }}
                onChange={(svalue) => {
                  fm.setFieldValue("catID", svalue);
                  const cat = listData.find((cat) => cat.id == svalue);
                  fm.setFieldValue("catName", cat.name);
                }}
                options={listData.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </div>
          </form>
        </div>
      </Drawer>

      <Drawer
        title="Tạo submenu mới"
        placement="right"
        onClose={onClose2}
        open={open2}
        width={window.innerWidth <= 800 ? "100%" : 800}
        extra={
          <Button type="primary" onClick={() => fm2.handleSubmit()}>
            Tạo menu
          </Button>
        }
      >
        <div className="drawer-content menu">
          <form>
            <div className="field name2-field">
              <label htmlFor="name2">Tên menu</label>
              <Input id="name2" className="name2" name="name2" value={fm2.values.name2} onChange={fm2.handleChange} />
            </div>

            <div className="field url2-field">
              <label htmlFor="url2">URL</label>
              <Input id="url2" className="url2" name="url2" value={fm2.values.url2} onChange={fm2.handleChange} />
              <Button style={{ marginLeft: 8 }} onClick={() => generateURL2()}>
                Generate
              </Button>
            </div>

            <div className="field catID2-field">
              <label htmlFor="catID2">Danh mục</label>
              <Select
                id="catID2"
                className="catID2"
                name="catID2"
                defaultValue={fm2.values.catID2}
                value={fm2.values.catID2}
                style={{ width: "100%" }}
                onChange={(svalue) => {
                  fm2.setFieldValue("catID2", svalue);
                }}
                options={listData.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
}
