import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import { applyMiddleware, combineReducers, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { sidebarReducer } from "./reducers/sidebarReducer";
import { userReducer } from "./reducers/userReducer";

const rootReducer = combineReducers({
  userReducer,
  sidebarReducer,
});

const middleWare = applyMiddleware(reduxThunk);
const composeCustom = composeWithDevToolsDevelopmentOnly(middleWare);

const store = createStore(rootReducer, composeCustom);

export default store;
