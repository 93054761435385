import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function Sidebar() {
  const dispatch = useDispatch();
  const { showSidebar } = useSelector((root) => root.sidebarReducer);

  const hideSideBar = () => {
    if (window.innerWidth <= 576) {
      dispatch({
        type: "TOGGLE_SIDEBAR",
      });
    }
  };

  return (
    <section className={`${showSidebar ? "sidebar" : "sidebar sidebar-collpase"}`}>
      <NavLink exact to="/dashboard" className="btn" activeClassName="btn-active" onClick={hideSideBar}>
        <i className="fa-solid fa-house"></i>
        <span>Dashboard</span>
      </NavLink>

      <NavLink exact to="/user" className="btn" activeClassName="btn-active" onClick={hideSideBar}>
        <i className="fa-solid fa-user-gear"></i>
        <span>Quản lý người dùng</span>
      </NavLink>

      <NavLink exact to="/category" className="btn" activeClassName="btn-active" onClick={hideSideBar}>
        <i className="fa-regular fa-rectangle-list"></i>
        <span>Quản lý menu</span>
      </NavLink>

      <NavLink exact to="/add-product" className="btn" activeClassName="btn-active" onClick={hideSideBar}>
        <i className="fa-solid fa-circle-plus"></i>
        <span>Thêm sản phẩm</span>
      </NavLink>

      <NavLink exact to="/product" className="btn" activeClassName="btn-active" onClick={hideSideBar}>
        <i className="fa-solid fa-rectangle-list"></i>
        <span>Quản lý sản phẩm</span>
      </NavLink>

      <NavLink exact to="/order" className="btn" activeClassName="btn-active" onClick={hideSideBar}>
        <i className="fa-solid fa-cart-shopping"></i>
        <span>Quản lý đơn hàng</span>
      </NavLink>

      <NavLink exact to="/image" className="btn" activeClassName="btn-active" onClick={hideSideBar}>
        <i className="fa-solid fa-image"></i>
        <span>Banner quảng cáo</span>
      </NavLink>
    </section>
  );
}
