import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";

import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";

export default function AdminTemplate(props) {
  const { showSidebar } = useSelector((root) => root.sidebarReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const Component = props.component;

  const toggleSidebar = () => {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
  };

  const renderMobileLayout = (propsRoute) => {
    return (
      <div className="admin-template">
        <Header {...propsRoute} />
        <Sidebar {...propsRoute} />

        <div className={`${showSidebar ? "overlay" : "overlay overlay-hidden"}`} onClick={toggleSidebar}></div>
        <div className="component fullwidth">
          <Component {...propsRoute} />
        </div>
      </div>
    );
  };

  const renderDesktopLayout = (propsRoute) => {
    return (
      <div className="admin-template">
        <Header {...propsRoute} />
        <Sidebar {...propsRoute} />

        <div className={`component${showSidebar ? "" : " component-expand"}`}>
          <Component {...propsRoute} />
        </div>
      </div>
    );
  };

  if (
    !localStorage.getItem("user") ||
    !localStorage.getItem("token") ||
    JSON.parse(localStorage.getItem("user")).id !== 1
  ) {
    return history.replace("/login");
  }

  if (window.innerWidth <= 576) {
    return (
      <Route
        exact
        path={props.path}
        render={(propsRoute) => {
          return renderMobileLayout(propsRoute);
        }}
      />
    );
  } else {
    return (
      <Route
        exact
        path={props.path}
        render={(propsRoute) => {
          return renderDesktopLayout(propsRoute);
        }}
      />
    );
  }
}
