import { Button, Image } from "antd";
import React, { useEffect, useState } from "react";
import { axiosService } from "../../util/service";
import { showAlert } from "../../util/showAlert";

export default function ImageComponent() {
  const [img, setImg] = useState(undefined); // for uploading
  const [path, setPath] = useState(undefined); // for showing preview

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setImg(file);
  };

  useEffect(() => {
    if (img) {
      const path = URL.createObjectURL(img);
      setPath(path);
    }
  }, [img]);

  const uploadBanner = async (img) => {
    try {
      let fd = new FormData();
      fd.append("image", img);

      let response = await axiosService.post("api/admin/uploadBanner", fd);
      showAlert(1, response.data.message);

      // reset
      setImg(undefined);
      setPath(undefined);
      document.getElementById("photoInput").value = "";
    } catch (error) {
      console.log(error);
      showAlert(2, error.response.data.data.message);
    }
  };

  return (
    <div className="image-component">
      <div className="title-area">
        <h2 className="title">Upload banner quảng cáo</h2>
      </div>

      <div className="f-row">
        <div className="photo-field field">
          <input type="file" accept="image/*" id="photoInput" name="photoInput" onChange={handleChangeImage} />
        </div>

        {img && path && (
          <Button type="primary" onClick={() => uploadBanner(img)}>
            Lưu hình ảnh
          </Button>
        )}
      </div>

      {path && (
        <div className="img-preview-wrapper">
          <Image src={path} alt="Image preview" className="img-preview" />
        </div>
      )}
    </div>
  );
}
